.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(#landing-hero) {
  & > div:nth-child(2) {
    max-width: 1260px;
    width: 100%;
    & header {
      display: block;
      max-width: 1260px;
      width: 100%;
      & h1 {
        max-width: 815px;
        margin: 0 0 14px;
        text-align: left;
        font-size: 64px;
        font-style: normal;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: -2.5px;
      }
      & p {
        text-align: left;
        max-width: 815px;
        margin: 0 0 0px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
        letter-spacing: -0.5px;
      }
    }
  }
}

.buttonsGroup {
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
  & a {
    width: 239px;
    height: 62px;
    color: #000 !important;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.267px;
    text-transform: capitalize;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 5px;
    text-decoration: none !important;
    &:hover{
      background: #fff;
    }
  }
}
